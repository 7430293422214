<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加线路广告</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom: 20px" />
    <el-form ref="formData" :model="formData" label-width="120px">
      <el-form-item label="广告位置">
        <el-select v-model="formData.advType" placeholder="请选择">
          <el-option
            v-for="item in advType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题">
        <el-input
          v-model="formData.title"
          maxlength="100"
          size="medium"
        ></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <send-image
          :images="formData.imgUrl"
          @addImageStr="addImageStr"
        ></send-image>
        <div>推荐尺寸：375px*187px</div>
      </el-form-item>
      <el-form-item label="显示平台">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checked"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="item in platform"
            :label="item.value"
            :key="item.value"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="广告位置">
        <el-select v-model="formData.skipType" placeholder="请选择">
          <el-option
            v-for="item in skipTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跳转ID">
        <el-input
          v-model="formData.skipId"
          maxlength="100"
          size="medium"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio v-model="formData.status" :label="0">隐藏</el-radio>
        <el-radio v-model="formData.status" :label="1">显示</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 20px 0 0"
          @click="onAdd()"
          >确定</el-button
        >
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 0"
          @click="$router.go(-1)"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
const platform = [
  { value: 1, name: "小程序" },
  { value: 2, name: "H5" },
  { value: 3, name: "IOS" },
  { value: 4, name: "Android" },
  { value: 5, name: "PC" },
];

import { addAd, adDetail } from "../../api/travel";
import sendImage from "@/components/sendImage.vue";
export default {
  components: {
    sendImage,
  },
  data() {
    return {
      checkAll: false,
      checked: [],
      checkVal: [],
      platform: platform,
      skipTypeOptions: [
        { value: 0, label: "不跳转" },
        { value: 1, label: "酒店列表页" },
        { value: 2, label: "酒店详细页面" },
        { value: 3, label: "门票列表页" },
        { value: 4, label: "门票详细页面" },
        { value: 5, label: "线路列表页" },
        { value: 6, label: "线路详细页面" },
        { value: 7, label: "拼团首页" },
        { value: 8, label: "秒杀首页" },
        { value: 9, label: "精选商城-商品详情" },
        { value: 10, label: "拼团详情" },
        { value: 11, label: "秒杀详情 " },
        { value: 12, label: "活动页" },
        { value: 13, label: "优惠券列表" },
      ],
      advType: [
        { value: 1, label: "分类页面" },
        { value: 2, label: "列表页面" },
      ],
      versionId: "",
      formData: {
        cateId: "", //所属分类
        advId: "0",
        advType: "",
        title: "",
        imgUrl: "",
        place: "",
        skipType: "",
        skipId: "",
        status: "",
      },
    };
  },
  created() {
    console.log("laskdjlask");
    this.formData.cateId = this.$route.query.id;
    this.formData.advId = this.$route.query.advId;
    console.log(this.formData);
    if (this.formData.pushId !== "0") {
      this.getFormDetail();
    }
  },

  methods: {
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.platform.length;
      this.formData.place = this.checked.toString();
      console.log(value, "value");
    },
    init() {
      for (let i = 0; i < this.platform.length; i++) {
        this.checkVal.push(this.platform[i].value);
      }
    },
    handleCheckAllChange(val) {
      this.checkVal = [];
      if (val) {
        console.log("刷新页面");
        this.init();
        console.log("if", this.checkVal);
        console.log(this.checked, "checked");
        this.checked = this.checkVal;
        this.formData.place = "0";
      } else {
        this.checked = [];
      }
    },
    addImageStr(image) {
      this.formData.imgUrl = image;
    },
    async getFormDetail() {
      const { data } = await adDetail({
        advId: this.formData.advId,
      });
      if (data.code == 0) {
        this.formData = data.data[0];
        this.formData.place = data.data[0].place;
        console.log(data.data);
        try {
          let arr = data.data[0].place.split(",");
          console.log(arr, "arr");
          let newArr = [];
          arr.map((e) => {
            newArr.push(Number(e));
          });
          console.log(newArr, "newArr");
          if (newArr[0] == 0 || newArr.length == this.platform.length) {
            this.checkAll = !this.checkAll;
            console.log(this.checkVal, "val");
            let checkedArr = [];
            for (let i = 1; i <= platform.length; i++) {
              checkedArr.push(i);
            }
            this.checked = checkedArr;
            this.checked = [1, 2, 3, 4, 5];
          } else {
            this.checkVal = newArr;
            this.checked = this.checkVal;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async onAdd() {
      let that = this;
      const { data } = await addAd(this.formData);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        setTimeout(() => {
          that.$router.go(-1);
        }, 500);
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
    },
    // sumitForm(formData) {
    //   this.$refs[formData].validate((valid) => {
    //     if (valid) {
    //       this.onAddPush();
    //     } else {
    //       this.onAddPush();
    //     }
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  width: 300px !important;
}
/deep/.el-textarea__inner {
  width: 500px !important;
  height: 200px !important;
}
.title {
  font-weight: bold;
  margin-left: -90px;
  span {
    height: 13px;
    width: 5px;
    background-color: #3082fd;
    display: inline-block;
    margin-right: 5px;
  }
}
</style>